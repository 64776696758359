<template>
  <base-section
    id="sommar-teater"
  >
    <base-section-heading title="Aktuella föreställningar">
    </base-section-heading>

    <v-container>
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          md="5"
        >
      <p>Klicka vidare för att se vårens planerade föreställningar och köp dina biljetter.
      </p>
          <div class="text-center">
            <base-btn
              :tile="false"
              color="primary"
              :to="{ name: 'Produktioner' }"
              class="ma-2"
              rounded
            >
              Till aktuella föreställningar
            </base-btn>
          </div>


        </v-col>

        <v-col
          cols="12"
          md="2"
        >
            <base-img
              max-width="100%"
              width="230"
              :src="require(`@/assets/banner2.jpg`)"
            />

        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionProChart',

    data: () => ({
      features: [
        ['Components', 23, '40+'],
        ['Example Pages', 3, 10],
        ['Bugfixes and Issues', true, true],
        ['Vue CLI Support', true, true],
        ['News, Project, and Pricing Pages', false, true],
        ['6 Months Free Updates', false, true],
        ['Price', 'Free', '$60'],
      ],
    }),
  }
</script>
